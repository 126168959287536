<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col><h1>Report</h1></v-col>
    </v-row>
    <!-- EOC -->
         <!-- BOC:[tabs] -->
    <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab v-for="item in tabs" :key="item.key" :href="`#${item.key}`">
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <!-- EOC -->
    <!-- BOC:[tab] -->
    <v-tabs-items v-model="tab">
      <!-- BOC:[model] -->
      <v-tab-item key="achievement" value="achievement" class="px-1 py-2">
       <Achievement>

       </Achievement>
  </v-tab-item>
      <!-- EOC -->

    </v-tabs-items>
    <!-- EOC -->
  </v-container>
  </template>
  
  <script>
    //BOC:[model]
    //EOC
    //BOC:[table]
    import Achievement from '@/components/Moderator/Report/Achievement'
    //EOC
    import { mapState } from 'vuex'
    export default {
      components:{
        //BOC:[table]
        Achievement,
        //EOC
      },
      computed: mapState({
        school: state => state.school.data,
      }),
      props:[
        //
      ],
      data:()=>({
         //BOC:[tabs]
     tab:null,
      tabs:[
        {
          key:'achievement',
          label:'Achievement',
        },
      ],
      //EOC
        //BOC:[breadcrumbs]
        breadcrumbs: [],
        //EOC
        //BOC:[model]
        //EOC
        //BOC:[role]
        role:'Moderator',
        //EOC
      }),
      created() {
        //BOC:[breadcrumbs]
        this.breadcrumbs.push({
          text:'Report',
          to:{name:'Page'+this.role+'Report'},
          exact:true,
        })
        //
        //EOC
      },
      mounted() {
        //
      },
      methods: {
        //
      }
    }
  </script>