<template>
  <div class="mb-3">
    <v-simple-table v-if="!api.isLoading">
      <thead>
        <tr>
          <th>Achievement Name</th>
          <th v-for="level in maxLevels" :key="'level-header-' + level">
            Level {{ level }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="achievement in data" :key="achievement.achievementKey">
          <td>{{ JSON.parse(achievement.translatableName)[$_getLocale()] }}</td>
          <td v-for="level in maxLevels" :key="'level-data-' + level">
            {{ getLevelCount(achievement.levels, level) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div v-else class="pa-3">
      <ALoader :isLoading="true"></ALoader>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: ["group"],
  data: () => ({
    maxLevels: [0, 1, 2, 3, 4, 5],
    data: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.game}/api/v1/en/moderator/report/achievement`;
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.data = resp;
      this.api.isLoading = false;
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$api.fetch(this.api);
    },
    getLevelCount(levels, targetLevel) {
      const level = levels.find((l) => l.level === targetLevel);
      return level ? level.count : 0;
    },
  },
};
</script>